import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import axios from "axios";
import region from "../config.json";
import apiUrl from "../config.json";

class CreateAvailability extends Form {

    state = {
        data: {
            weekDay: "",
            time: "",
            school: "",
          },
        regionList: [],
          errors: {},
    };

    schema = {
      //Joi schema requirements for input validation
        weekDay: Joi.string().min(6).max(9).required(),
        time: Joi.string().min(8).max(15).required(),
        school: Joi.string().max(100).required(),
        region: Joi.string().required().label("Region")
      };

    async componentWillMount(){
      //get the regions from the API
        const {data: regionListRaw} = await axios.get("https://reading-allies-api.herokuapp.com/api/" + "region");
        //map the regions to a new list and split them to get the Ids and Names
        const regionListSecondary = regionListRaw.map(function(regionListRaw){
            return {_id: regionListRaw.region_ID.toString(), name: regionListRaw.regionName};      
        });
        //filter the region list by 'National' and set it to the state 
        const regionList = regionListSecondary.filter(r => r.name !== 'National');
        this.setState({regionList});
    }

    doSubmit = () => {
        const data = this.state.data;
        this.submitChange(data);
      };

      async submitChange(output){
        //Windows pop up box asking for user confirmation of data
        const confirmBox = window.confirm("Are you sure you want to add this availability?")
        if (confirmBox === true) {
          //Try - Post the the new availability to the API and push the page back to the availabilities data table  
          try{
                await axios.post("https://reading-allies-api.herokuapp.com/api/" + "availability", { weekDay: output.weekDay, time: output.time, schoolName: output.school, region_ID: output.region});
                this.props.history.push("/availabilities");
            }
            //Catch - check for errors incase of failure
            catch(error){
                console.log('SubmitChange - Failure');
            }
        }
      }

    render() {
        return (
          <div>
            <h1 style = {{textAlign: "center", fontSize: "40px"}}>Create Availability</h1>
            {/* Form creation */}
            <form onSubmit={this.handleSubmit}>
                {this.renderSelect("region", "Region", this.state.regionList)}
                {this.renderInput("weekDay", "Week Day")}
                {this.renderInput("time", "Time (Ex: 10:00AM-10:45AM)")}
                {this.renderInput("school", "School")}
                {this.renderButton("Submit")}
            </form>
          </div>
        );
      }
}

export default CreateAvailability;