import React, { Component } from 'react';
import claire from '../resources/images/1902015_MW_0011_claire_stebbins_honors.jpeg';
import vicki from '../resources/images/1611047_ZR_003_Vicki_Holt.jpg';
import rob from "../resources/images/1805048_ZR_003_Dr_Robert_Witt-e1557857999378.jpg";
import book from "../resources/images/book.jpg";
import styles from '../resources/css/proj.module.css';
import '../resources/css/more.css';


class Team extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                <div className="shadow team" ><h1 className="Head">Our Team</h1></div>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/svg-with-js.min.css" integrity="sha512-W3ZfgmZ5g1rCPFiCbOb+tn7g7sQWOQCB1AkDqrBG1Yp3iDjY9KYFh/k1AWxrt85LX5BRazEAuv+5DV2YZwghag==" crossorigin="anonymous" referrerpolicy="no-referrer" />
                <section className="team-section py-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6">
                                <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                    <div className="card-body p-4">
                                        <div className="member-profile position-absolute w-100 text-center">
                                            <img className="rounded-circle mx-auto d-inline-block shadow-sm" src={claire} alt="Claire Stebbins headshot"/>
                                        </div>
                                        <div className="card-text pt-1 cardHeight">
                                            <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Claire Stebbins</h5>
                                            <div className="mb-3 text-center">Co-Director</div>
                                            <div className='sizeF'>Claire Stebbins serves as Co-Director of Reading Allies. As co-director, Claire mobilizes local 
                                                businesses, companies, school systems, nonprofits, and government entities to invest in collaborative, 
                                                community-driven solutions to meet the needs of below grade level readers. From grandparents to CEOs,
                                                Claire engages a vast network of volunteers and donors focused on building relationships and improving the 
                                                educational landscape for the next generation. In addition, Claire leads all volunteer training and community
                                                engagement efforts.  

                                                <br/><br/>

                                                Before Reading Allies, Claire served as an education consultant 
                                                in Chicago, Nashville, Washington DC, New Mexico, and Ohio. Claire is a graduate of the University of Alabama and 
                                                the proud daughter of two public school teachers. <br/><br/><br/><br/><br/><br/></div>
                                        </div>
                                    </div>
                                    {/* <!--//card-body--> */}
                                    <div className="card-footer theme-bg-primary border-0 text-center">
                                        <ul className="social-list list-inline mb-0 mx-auto">
                                            <li className="list-inline-item"><a className="text-dark" href="#">readingallies@gmail.com </a></li>
                                            {/* <li className="list-inline-item"><a className="text-dark" href="#">(205)-348-5537</a></li> */}
                                        </ul>
                                        {/* <!--//social-list--> */}
                                    </div>
                                    {/* <!--//card-footer--> */}
                                </div>
                                {/* <!--//card--> */}
                            </div>
                            {/* <!--//col--> */}
                            
                            <div className="col-12 col-md-6">
                                <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                    <div className="card-body p-4">
                                        <div className="member-profile position-absolute w-100 text-center">
                                            <img className="rounded-circle mx-auto d-inline-block shadow-sm" src={vicki} alt="Vicki Holt headshot"/>
                                        </div>
                                        <div className="card-text pt-1 cardHeight">
                                            <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Vicki Holt</h5>
                                            <div className="mb-3 text-center">Co-Director</div>
                                            <div className='sizeF'>Vicki Holt is a lifelong educator constantly seeking ways to improve the educational landscape 
                                                for the nation's highest-need students. As Co-Director of Reading Allies, Vicki is responsible for 
                                                all curriculum components. Vicki has spent her 30+ year teaching career in schools with high concentrations 
                                                of at-risk students across West Alabama, later serving with the Alabama Reading Initiative as a trainer of teachers 
                                                and reading coaches across the state. In addition to her role with Reading Allies, Vicki serves as Director of 
                                                Educational Outreach for the University of Alabama Honors College. As Director of Educational Outreach, Vicki oversees 
                                                various mentoring programs that serve students in Pre-K through first-generation college students. A graduate of The 
                                                University of Alabama with both undergraduate and master’s degrees in Early Childhood Education, she also completed extensive 
                                                post-graduate work in reading development and instruction with a focus on struggling readers. Vicki serves as the Chairwoman for
                                                 the West Alabama Literacy Council and is active in the Tuscaloosa community. <br/><br/> </div>
                                        </div>
                                    </div>
                                    {/* <!--//card-body--> */}
                                    <div className="card-footer theme-bg-primary border-0 text-center">
                                        <ul className="social-list list-inline mb-0 mx-auto">
                                            <li className="list-inline-item"><a className="text-dark" href="#">vsholt@ua.edu</a></li>
                                            {/* <li className="list-inline-item"><a className="text-dark" href="#">(205)-348-5550</a></li> */}
                                        </ul>
                                        {/* <!--//social-list--> */}
                                    </div>
                                    {/* <!--//card-footer--> */}
                                </div>
                                {/* <!--//card--> */}
                            </div>

                            
                            <div className="col-12 col-md-6">
                                <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                    <div className="card-body p-4">
                                        <div className="member-profile position-absolute w-100 text-center">
                                            <img className="rounded-circle mx-auto d-inline-block shadow-sm" src={book} alt="Reading Allies"/>
                                        </div>
                                        <div className="card-text pt-1">
                                            <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Reading Allies</h5>
                                            <div className="mb-3 text-center">-</div>
                                            {/* <div>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Vivamus eget eros vestibulum, accumsan ante viverra, condimentum tellus. Curabitur pellentesque convallis purus non ornare. Donec bibendum sed purus dignissim rutrum. Maecenas bibendum feugiat est, et venenatis nunc.</div> */}
                                        </div>
                                    </div>
                                    {/* <!--//card-body--> */}
                                    <div className="card-footer theme-bg-primary border-0 text-center">
                                        <ul className="social-list list-inline mb-0 mx-auto">
                                            {/* <button className="sm" href="https://www.facebook.com/ReadingAllies/"><svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg></button>
                                            <button className='sm' href="https://www.instagram.com/readingallies/"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></button>
                                            <button className='sm' href="https://twitter.com/readingallies?s=21"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg></button>
                                             */}
                                        </ul>
                                        {/* <!--//social-list--> */}
                                    </div>
                                    {/* <!--//card-footer--> */}
                                </div>
                                {/* <!--//card--> */}
                            </div>
                        
                    </div>
                    </div>
                    
                </section>

            </React.Fragment>
        );
    }
}
 
export default Team;