import React, { Component } from 'react';
import '../resources/css/proj.module.css';
import logo from '../resources/images/book2.png';

class Footer extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
        <footer tabindex="-1" className="footer">
        <div class="">
            <img className="footerLogo" src={logo} alt="book logo"/><div></div>
            <div className="SocialMed">
                <ul>
                    <li>
                        <a href="https://www.instagram.com/readingallies/" target="_blank"><wix-image><img alt="Instagram" class="socialIcon" src="https://static.wixstatic.com/media/01c3aff52f2a4dffa526d7a9843d46ea.png/v1/fill/w_36,h_36,al_c,q_85,usm_0.66_1.00_0.01/01c3aff52f2a4dffa526d7a9843d46ea.webp"/></wix-image></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/ReadingAllies/" target="_blank" rel="noopener"><wix-image alt="facebook"><img class="socialIcon" src="https://static.wixstatic.com/media/0fdef751204647a3bbd7eaa2827ed4f9.png/v1/fill/w_36,h_36,al_c,q_85,usm_0.66_1.00_0.01/0fdef751204647a3bbd7eaa2827ed4f9.webp"/></wix-image></a>
                    </li>
                    <li><a href="https://twitter.com/readingallies?s=21" target="_blank" rel="noopener"><wix-image><img class="socialIcon" alt="Twitter" src="https://static.wixstatic.com/media/c7d035ba85f6486680c2facedecdcf4d.png/v1/fill/w_36,h_36,al_c,q_85,usm_0.66_1.00_0.01/c7d035ba85f6486680c2facedecdcf4d.webp"/></wix-image></a>
                    </li>
                </ul>
            </div>
            <div className="copyright"><p class="font_8"><span style={{color: "#00000"}}><span><span style={{font: 20}}>readingallies@gmail.com</span></span></span></p></div>
            <div className="copyright"><p class="font_8"><span style={{color:"#00000"}}><span style={{fontStyle:"italic"}}><span style={{fontSize:11}}>©Copyright 2022. All Rights Reserved. Reading Allies.</span></span></span></p></div>
        </div>
    </footer>
    </React.Fragment>
        );
    }
}
 
export default Footer;