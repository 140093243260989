import React from 'react';
import Joi  from 'joi-browser';
import axios from 'axios';
import Form from "./common/form";
import apiUrl from '../config.json'

class CreateRegion extends Form {
    state = { 
        data: {
            region: ""
        },
        errors: {}
     } 

    schema = {
        region: Joi.string().required().label("Region")
    }

    doSubmit = async () => {
        //confirm box for creating the region in the form
        const confirmBox = window.confirm("Are you sure you want to add this region?")
        if (confirmBox === true) {
        //post the region to the API and push the page to the region list
        await axios.post("https://reading-allies-api.herokuapp.com/api/" + "region", {regionName: this.state.data.region});
        this.props.history.push("/regions");
        }
    }

    render() { 
        return (
            <div>
                <h1 style = {{textAlign: "center", fontSize: "40px"}}>Create Region</h1>
                <form onSubmit={this.handleSubmit}>
                {this.renderInput("region", "Region")}
                {this.renderButton("Register")}
                </form>
            </div>
        );
    }
}
 
export default CreateRegion;