import React, {Component} from 'react';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
//For API Requests
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import apiUrl from '../config.json'

class VolunteerDataTable extends Component {
    state = { data:[],
        region:[],
        availabilities: [],
        volAvail: [] } 
        async componentDidMount(){
            await this.getVolunteers();
            await this.getRegions();
            await this.getVolunteerRegion();
            await this.getAvailabilities();

            
            $(document).ready(function () {
                setTimeout(function(){
                $('#example').DataTable();
                 } ,1000);
            });
        }

        async getVolunteers(){
          //get the volunteers from the API
            await axios.get("https://reading-allies-api.herokuapp.com/api/" + 'volunteer').then(res => 
            {
              //if the region of the coordinator is national than we pull all of the data into the index
              if(sessionStorage.getItem('region') === '574'){
                this.setState({data: res.data});  
              }
              //else if its a regional coordinator than we only pull volunteers with the same region
              else{
                const filterId = Number(sessionStorage.getItem('region'));
                const toFilter = res.data;
                var filtered = [];
                var counter = 0;
                for(var i = 0; i < toFilter.length; i++){
                  if(filterId === toFilter[i].region_ID){
                    filtered[counter] = toFilter[i];
                    counter = counter + 1;
                  }
                }
                this.setState({data: filtered});
              }    
            });
        }


        async getRegion(result){

          var res;
          const regions = this.state.region;
          regions.map((region) => {
            if(region.region_ID === result.region_ID){
              res = region.regionName;
            }
          });
            var data = this.state.data;
            data.map((r) => {
                if (result.volunteer_ID === r.volunteer_ID)
                 result.regionName = res;
             })
             this.setState({data});
        }


        async getVolunteerRegion(){
          var data = this.state.data;
          var res;
         this.state.data.map((result) => {
              res = this.getRegion(result);
          });
          (this.setState({data}));
    }


        async getRegions(){
          const regions = await axios.get("https://reading-allies-api.herokuapp.com/api/" + 'region');
          this.setState({region: regions.data});
        }

        //Stores all of the regions from the state into the a new list that is filtered to delete the selected volunteer
        handleDelete = async volunteer => {
          const originalVolunteers = this.state.data;
          const volunteers = originalVolunteers.filter(v => v.volunteer_ID !== volunteer.volunteer_ID);
          this.setState({ data: volunteers });
          //try to delete the selected volunteer from the API
          try {
            await axios.delete("https://reading-allies-api.herokuapp.com/api/" + 'volunteer/' + volunteer.volunteer_ID);
          } 
          //catch to check for errors
          catch (ex) {
            if (ex.response && ex.response.status === 404)
              toast.error("This volunteer has already been deleted.");
              //if an error is caught the volunteers are restored to the original state
              this.setState({ data: originalVolunteers });
          }
          
          
        };
        async getAvailabilities(){
            await axios.get("https://reading-allies-api.herokuapp.com/api/" + 'volavail/').then(res => 
            {
              //Storing users detail in state array object
              this.setState({volAvail: res.data});      
            });
            await axios.get("https://reading-allies-api.herokuapp.com/api/" + 'availability/').then(res => 
            {
              //Storing users detail in state array object
              this.setState({availabilities: res.data});      
            });
            var volunteers = this.state.data;
            var check;
            var check1;
            
            volunteers.map(result => {
                var checkValue = "";
                //get all instances from volAvail where the volunteer is present/has availability recorded
                check1 = this.state.volAvail.filter(v => v.volunteer_ID == result.volunteer_ID);
                //take each instance to find its individual school, time and day
                check1.map(c => {
                    if (c === null) return;
                    //filter based on the individual availability's ID from the volavail record
                    check = this.state.availabilities.filter(a => a.availability_ID == c.availability_ID)
                    if (check === null) return;
                    var line = check[0].schoolName + " " + check[0].time + " " + check[0].weekDay + ", ";
                    checkValue += line;
                    result.availabilityInfo = checkValue;                                        
                })
            });
            this.setState({data: volunteers});
        }
        
    
    render() { 
        return (
            <div className="MainDiv">
              {/* Jumbotron */}
      <div className="jumbotron text-center" style= {{backgroundColor: "#9e1b32"}}>
          <h3 style={{fontSize: "50px", color: "white"}}>Reading Allies Volunteers</h3>
      </div>
      {/* button that links to the register form */}
      <div className="d-flex justify-content-between">
        <Link to="/volunteer">
          <button className="btn btn-primary m-2">
            Create Volunteer
          </button>
        </Link>
        {/*CSV Link to generate Excel file with all volunteer information in the headers below */}      
        <CSVLink
                data={this.state.data}
                headers={[
                  { label: "First Name", key: "firstName" },
                  { label: "Last Name", key: "lastName" },
                  { label: "Cell Phone", key: "phoneNumber" },
                  { label: "Email", key: "email" },
                  { label: "Facilitator?", key: "facilitator" },
                  { label: "Multiple Sessions?", key: "multipleSessions" },
                  { label: "Availability", key: "availabilityInfo" },
                  { label: "Address Line 1", key: "mailingAddressLineOne" },
                  { label: "Address Line 2", key: "mailingAddressLineTwo" },
                  { label: "Zip Code", key: "zipCode" },
                  { label: "Notes", key: "comments" },
                  { label: "How did you hear?", key: "heardFrom" },
                  { label: "Date Submitted", key: "timeStamp"}
                ]}
          filename={"volunteers.csv"}
        >
          <button className="btn btn-primary m-2">
            Download Volunteers
          </button>
        </CSVLink>
      </div>
      <div className="container">
        {/* table creation and table headers */}
          <table id="example" className="table table-hover table-bordered table-striped">
          <thead className="table-light p-3">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Region</th>
              <th>Availability</th>
              <th>Multiple Sessions</th>
              <th>Details</th>
              <th>Delete</th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody>
          {this.state.data.map((result) => {
            //maps result to return rows of data with filled information
            return (
            
                 <tr key={result.volunteer_ID}>
                  <td>{result.firstName} {result.lastName}</td>
                  <td>{result.email}</td>
                  <td>{result.regionName}</td>
                  <td>{result.availabilityInfo}</td>
                  <td>{result.multipleSessions ? "Yes" : "No"}</td>
                  {/* Details button that brings user to an edit volunteer form */}
                  <td>
                    <Link to={`/volunteers/${result.volunteer_ID}`}>
                      <button className="btn btn-primary">
                        Details</button></Link></td>
                  {/* Delete button that asls user for confirmation */}
                  <td><button className="btn btn-danger"style={{marginTop:"10px"}}
                  onClick={() => {
                    const confirmBox = window.confirm("Do you want to delete this info?")
                    if (confirmBox === true) {
                      this.handleDelete(result)
                    }
                  }}>Delete</button></td>
                </tr>             
            )
          })}             
          </tbody>
        </table>
        </div>
      </div>
        );
    }
}
 
export default VolunteerDataTable;