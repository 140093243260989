import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Link from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faShieldHeart, faBookOpenReader } from '@fortawesome/free-solid-svg-icons';
import '../resources/css/v2.css';
import banner from "../resources/images/headerPhotosmall.jpg";
import photo1 from "../resources/images/photo1.jpg";
import photo2 from "../resources/images/photo4.jpg";
import photo3 from "../resources/images/photo2.jpg";
import photo4 from "../resources/images/photo3.jpg";
import photo5 from "../resources/images/photo6.jpg";
import photo6 from "../resources/images/photo5.jpg";
import photo7 from "../resources/images/HOLTELEM-2019-59.jpg";
import photo8 from "../resources/images/results2.jpg";
import Involved from './involved';
import About from './about';
import YoutubeEmbed from './common/youtubeEmbed';


var photo = {
    marginTop: "40px",
    width: "auto",
    height: "600px",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url( ${ banner } )`
};

class Home extends Component {
    state = {  } 
    render() { 
        // console.log(banner);

        return (
            <React.Fragment>
                {/* <div className='home' style={photo} alt='Child with volunteer laughing'><h1 className='home-title centered overlay'>Reading Allies</h1></div> */}
                <div className="video-responsive">
                    <YoutubeEmbed embedId="Uw7cKbuLi3A" />
                </div>
                <div className='home'><h2 className='home-redHeader centered'>Building Readers. Cultivating Community.</h2></div>
                <div className='home'>
                    <a className='body-text'>Reading Allies is an innovative literacy program that helps below grade-level readers in first, second, and 
                    third grade rise to grade-level standards through tailored, in-depth community tutoring. With over half of students 
                    across the country not meeting grade-level reading standards, Reading Allies is using proven strategies to invest in
                    our students, schools and society.</a>
                </div>
                <Container className='grid-container top bottom'>
                    <Row className='row'>
                        <Col xs><img className='top bottom pop' src={photo1}/></Col>
                        <Col xs><img className='top bottom pop' src={photo2}/></Col>
                        <Col xs><img className='top bottom pop' src={photo3}/></Col>
                    </Row>
                    <Row className='row center-grid'>
                        <Col sm><img className='grid-side top bottom pop' src={photo4}/></Col>
                        <Col sm><img className='grid-side top bottom pop' src={photo5}/></Col>
                    </Row>
                    <Row className='row'>
                        <Col xs><img className='top bottom pop' src={photo6}/></Col>
                        <Col xs><img className='top bottom pop' src={photo7}/></Col>
                        <Col xs><img className='top bottom pop' src={photo8}/></Col>
                    </Row>
                </Container>
                {/* <div className="video-responsive">
                    <YoutubeEmbed embedId="Uw7cKbuLi3A" />
                </div> */}
                {/* <Row className='icon-row top bottom'>
                    <Col xs><a href='/about'><button className='round'><FontAwesomeIcon className='icon bOne' icon={faPeopleGroup} /></button></a><a className='buttonTxt' href='/about'>About Us</a></Col>
                     <Col xs><button className='round' href=''><FontAwesomeIcon className='icon bTwo' icon={faShieldHeart} /></button><a className='buttonTxt' >Give</a></Col> 
                    <Col xs><button className='round' href=''><FontAwesomeIcon className='icon bThree' icon={faBookOpenReader} /></button><a className='buttonTxt' >Get Involved</a></Col>
                </Row> */}

            </React.Fragment>
        );
    }
}
 
export default Home;