import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import NotFound from "./components/notFound";
import NavBar from "./components/navBar";
import RegisterForm from "./components/registerForm";
import NewCoordinatorForm from "./components/newCoordinatorForm"
import LoginForm from "./components/loginForm";
import ProtectedRoute from "./components/common/protectedRoute";
import GlobalRoute from "./components/common/globalRoute";
import AdminHomepage from "./components/adminHomepage";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import axios from 'axios';
import VolunteerDataTable from "./components/volunteerDataTable";
import CoordDataTable from "./components/coordDataTable";
import EditCoordinatorForm from "./components/editCoordinatorForm";
import EditVolunteer from './components/editVolunteer';
import Logout from "./components/logout";
//import { update } from "lodash";
//import { region, admin, active } from "./components/loginForm";
//import { isNullOrUndefined } from "joi-browser";
import ChangePassword from './components/changePassword';
import RegionDataTable from "./components/regionDataTable";
import CreateRegion from './components/createRegion';
import AvailabilityDataTable from './components/availabilityDataTable';
import CreateAvailability from './components/createAvailability';
import Footer from './components/footer';
import About from './components/about';
import Team from './components/team';
import Involved from './components/involved';
import Stories from "./components/stories";
import Home from './components/home';


class App extends Component {
  state={
    loggedIn: false,
    adminId: "",
    regionId: "",
  };
    

      checkCredentials(){
        if(sessionStorage.getItem('active') && this.state.loggedIn === false){
          const active = sessionStorage.getItem('active');
          console.log('here');
          console.log(active);
          this.setState({loggedIn: true});
        }

      }

      componentWillMount(){
        if(!sessionStorage.getItem('active') || sessionStorage.getItem('active')!== 'true'){
          sessionStorage.setItem('active', false);
          sessionStorage.setItem('region', '');
        }
        
        
      }
      
      render() {
        return (
          <React.Fragment>
            
        <NavBar />
        <main className="container">
          <Switch>
          {/* <Route path='/' exact component={Home}/>
          <Route path="/about" component={About} />
          <Route path="/team" component={Team} />
          <Route path="/involved" component={Involved} />
          <Route path='/results' component={Stories}/> */}
          {/* <Route path={{pathname: "https://readingallies.herokuapp.com"}} /> */}
          {/* <Route path='/readingallies' component={() => {window.location.href = "https://readingallies.herokuapp.com" ; return null}}/> */}

            
            <Route path="/volunteer" component={RegisterForm} />
            <Route path='/' exact component={Home}/>
            <Route path="/about" component={About} />
            <Route path="/team" component={Team} />
            <Route path='/results' component={Stories}/>
            {/* <Route path='/' exact component={RegisterForm}/> */}
            <Route path="/login" component={LoginForm} />
            <ProtectedRoute path="/change-password" loggedIn={sessionStorage.getItem('active')} component={ChangePassword}/>
            <GlobalRoute path="/regions" loggedIn={sessionStorage.getItem('region')} component={RegionDataTable}/>
            <GlobalRoute path="/register-region" loggedIn={sessionStorage.getItem('region')} component={CreateRegion}/>
            <GlobalRoute path="/new-availability" loggedIn={sessionStorage.getItem('region')} component={CreateAvailability}/>
            <ProtectedRoute path="/volunteers/:id" loggedIn={sessionStorage.getItem('active')} component={EditVolunteer} />
            <ProtectedRoute path="/volunteers" loggedIn={sessionStorage.getItem('active')} component={VolunteerDataTable}/>
            <ProtectedRoute path="/availabilities" loggedIn={sessionStorage.getItem('active')} component={AvailabilityDataTable}/>
            <GlobalRoute path="/coordinators/:id" loggedIn={sessionStorage.getItem('region')} component={EditCoordinatorForm} />
            <GlobalRoute path="/coordinators" loggedIn={sessionStorage.getItem('region')} component={CoordDataTable}/>

            <GlobalRoute path="/register-coordinator" loggedIn={sessionStorage.getItem('region')} component={NewCoordinatorForm} />
            <ProtectedRoute path="/admin" loggedIn={sessionStorage.getItem('active')} component={AdminHomepage} />
            <ProtectedRoute path="/logout" loggedIn={sessionStorage.getItem('active')} component={Logout} />
            <Route path="/not-found" component={NotFound} />
            {/* <Redirect from="/" exact to="/" /> */}
            <Redirect to="/not-found" />
          </Switch>
        </main>
        <Footer/>
      </React.Fragment>
    );
  }
}



export default App;
