import React, { Component } from 'react';
import styles from '../resources/css/proj.module.css';
import banner from '../resources/images/headerPhotosmall.jpg';
import nongroup from '../resources/images/NONGROUP.jpg';
import group from '../resources/images/GROUP.jpg';


class About extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                <div className="shadow abt"><h1 className="Head">About</h1></div>
                <div className='body-text about-pad'><i>Reading Allies developed out of a simple question: <br></br>What happens when communities intentionally work together to help their <br></br>students read on grade level? </i></div>
                {/* <h2 className='subhead'>History</h2> */}
                <div className='body-text-left'>Since 2017, Reading Allies has provided the curricular and community structures and support necessary to move students from struggling readers to thriving readers at high rates, regardless of zip code. Data indicates that after one year of Reading Allies programming, 80% of participating students either meet or exceed grade level reading standards. </div>
                <img className='aboutimg' src={nongroup}/>
                <div className='body-text-left'><p>How do students make such drastic growth in only 20 weeks? Individualized curriculum and consistent community tutors. Reading Allies Specialists – local retired or part-time teachers – assess and design individualized lessons for participating students following the Reading Allies curriculum structure. Those individualized lessons are then taught one-on-one by trained community volunteers.</p>
                    <p>Reading Allies is <b>always</b> looking to help communities come together to help their students read on grade level. Contact us to start building readers in your community!  </p>
                </div>
                <img className='aboutimg' src={group} />
            </React.Fragment>
        );
    }
}
 
export default About;