import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import axios from "axios";
import fName from "../config.json";
import lName from "../config.json";
import region from "../config.json";
import phoneNumber from "../config.json";
import email from "../config.json";
import address1 from "../config.json";
import address2 from "../config.json";
import city from "../config.json";
import state from "../config.json";
import zip from "../config.json";
import apiUrl from "../config.json";
// import {
//   fName,
//   lName,
//   region,
//   phoneNumber,
//   eMail as email,
//   address1,
//   address2,
//   city,
//   state,
//   zip,
//   apiUrl,
// } from "../config.json";

class EditCoordinatorForm extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      address1: "",
      address2: "", 
      city: "",
      state: "",
      zipCode: "",
      access: ""
    },
    errors: {},
    regionList: [],
    volId: ''
  };

  schema = {
    //Joi schema requirements for validation
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().required().label("Last Name"),
    email: Joi.string().max(50).required().email().label("Email"),
    phoneNumber: Joi.number().required().label("Phone Number"),
    access: Joi.string().required().label("Region"),
    password: Joi.string().allow("").label("Password"), 
    address1: Joi.string().allow("").label("Address Line 1"),
    address2: Joi.string().allow("").label("Address Line 2"),
    city: Joi.string().allow("").label("City"),
    state: Joi.string().allow("").label("State"),
    zipCode: Joi.string().allow("").label("Zip Code")
  };

  async componentDidMount() {
    //Imports regions from the API and gets the region ID and region name of the selected coordinator then stores it in the state
    const {data: regionListRaw} = await axios.get("https://reading-allies-api.herokuapp.com/api/" + "region");
    const regionList = regionListRaw.map(function(regionListRaw){
      return {_id: regionListRaw.region_ID.toString(), name: regionListRaw.regionName};
    });
    this.setState({regionList});

    //gets the admin information from the API then passes it to populate the form
    const {data: coordinator} = await axios.get("https://reading-allies-api.herokuapp.com/api/" + "admin/" + this.state.volId + "?admin_ID=" + this.state.volId);
    this.populateForm(coordinator);
  }

  async componentWillMount(){
    //Gets the ID of the coordinator from the url
    var path = window.location.pathname;
    var split = path.split('/');
    this.setState({volId: split[split.length - 1]});
  }

  doSubmit = async () => {
    //gets the data from the state to handle password edits
    const data = this.state.data;
    //if the user did not enter a new password in the form than the password stays the same
    var newPass;
    if(data.password === ""){
      newPass = this.state.oldPassword;
    }
    //else the user enters a new password than it is hashed and stored in a variable for the axios.put
    else{
      const passwordHash = require('bcryptjs');
      const salt = passwordHash.genSaltSync(10);
      //console.log(salt); 
      const hashed = passwordHash.hashSync(data.password, salt);
      newPass = hashed;
    }
    //Windows drop down to ensure the user wants to edir the information
    const confirmBox = window.confirm("Do you want to edit this info?")
    if (confirmBox === true) {
      //if the user confirms than we try to put the new coordinator information into the API and push the page back to the coordinator index  
      try{
            await axios.put("https://reading-allies-api.herokuapp.com/api/" + "admin/" + this.state.volId , {admin_ID: this.state.volId, firstName: data.firstName, lastName: data.lastName, email: data.email, phoneNumber: data.phoneNumber, mailingAddressLineOne: data.address1, mailingAddressLineTwo: data.address2, city: data.city, state: data.state, zipCode: data.zipCode, password: newPass, region_ID: Number(data.access)});
            this.props.history.push("/coordinators");
        }
        //catch for errors just incase something is off
        catch(error){
            console.log('ERROR');
        }
    }
    

  };

  mapToView = admin => {
    //take the variables from the passed admin and set them in the state
    const data = this.state.data;
    data.firstName = admin.firstName,
    data.lastName = admin.lastName,
    data.email = admin.email,
    data.phoneNumber = admin.phoneNumber,
    data.address1 = admin.mailingAddressLineOne,
    data.address2 = admin.mailingAddressLineTwo,
    data.city = admin.city,
    data.state = admin.state,
    data.zipCode = admin.zipCode,
    data.access = admin.region_ID.toString(),
    this.setState({data})
  }

  async populateForm(coordinator) {
    //try - set the state of the password of the passed coordinator and then map to view the data to the state  
    try{
        this.setState({oldPassword: coordinator.password});
        this.mapToView(coordinator);
        //Catch - error checking to ensure nothing went wrong
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
            console.log("ERROR");
      }
  }

  render() {
    return (
      //Creating the Form and populating it with input fields 
      <div>
        <h1 style = {{textAlign: "center", fontSize: "40px"}}>Edit Coordinator Form</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("firstName", "First Name")}
          {this.renderInput("lastName", "Last Name")} 
          {this.renderInput("email", "Email")}
          {this.renderInput("phoneNumber", "Phone Number")}
          {this.renderInput("address1", "Address Line 1")}
          {this.renderInput("address2", "Address Line 2")}
          {this.renderInput("city", "City")}
          {this.renderInput("state", "State")}
          {this.renderInput("zipCode", "Zip Code")}
          {this.renderInput("password", "Edit Password - Leave blank to keep current password")}
          {this.renderSelect("access", "Region", this.state.regionList)}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default EditCoordinatorForm;
