import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import axios from "axios";
import {Redirect } from "react-router-dom";
import apiUrl from '../config.json'
var active2 = false;
var region2 = "";
var admin2 = "";

class LoginForm extends Form{
  state = {
    data: { username: "", password: "" },
    login: [],
    errors: {},
    active: false,
    adminId: "",
    regionId: ""
  };

  schema = {
    //Joi schema validation requirements
    username: Joi.string().max(50)
      .required().email()
      .label("Username"),
    password: Joi.string().max(50)
      .required()
      .label("Password"), 
  };

  doSubmit = () => {
    var valid = false;
    var admin = [];
    const passwordHash = require('bcryptjs');
    //if the stored password is equal to the user password than verify and set the login to the state
    for(var i = 0; i < this.state.login.length; i++){
      if(this.state.data.username === this.state.login[i].email){
        valid = passwordHash.compareSync(this.state.data.password, this.state.login[i].password);
        // valid = ""
        console.log(valid);
        admin = this.state.login[i];
      }
    }
    //if valid is true 
    if(valid){
      this.state.active = true;
      this.state.adminId = admin.admin_ID;
      this.state.regionId = admin.region_ID;
      this.checkState();
    }
    //else handle the case of incorrect
    else{
      alert("Invalid Username or Password. Please try again.");
      var reset = this.state.data;
      reset.password = '';
      this.setState({data: reset});
    }
  };

  checkState(){
    //sets the state for export and creates a user session for authentication
    active2 = this.state.active;
    region2 = this.state.regionId;
    admin2 = this.state.adminId;
    sessionStorage.setItem('active',  true);
    sessionStorage.setItem('region', this.state.regionId);
    sessionStorage.setItem('admin', this.state.adminId);
  }

  reroute(){
    //if the user is validated through login than push them to the volunteer index
    if(sessionStorage.getItem('active') === 'true'){
      return(<Redirect
        to={{
          pathname: "/volunteers",
        }}
      />);
    }
  }

  async componentDidMount() {
    //axios get admin info from the API and set it to the state
    const {data: login} = await axios.get("https://reading-allies-api.herokuapp.com/api/" + "admin");
    this.setState({login});
  }

  returnActive(){
    return this.state.active;
  }

  render() {
    return (
      //handles the creation of the physical form
      <div>
        <h1>Login</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "Username")}
          {this.renderInput("password", "Password", "password")}
          {this.renderButton("Login")}
          {this.reroute()}
        </form>
      </div>
    );
  }
}

//export variables to be used in other places for the user session
export var active = active2;
export var region = region2;
export var admin = admin2;
export default LoginForm;
