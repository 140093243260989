import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import RadioButton from "./radioButton";
import RegularRadio from "./regularRadio";
import CheckBox from "./checkBox";

//all of the logic for the form
class Form extends Component {
  state = {
    data: {},
    errors: {},
    isChecked: false
    //console.log(isChecked);
  };
  //validates inputs that need to be properly filled out in order to submit the form
  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    //if there is no error, returns null, which is read as "falsy" and can be used as a boolean to allow the submit button to be clickable
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  //used to validate only one specific field
  validateProperty = ({ name, value }) => {
    //obj and schema selected dynamically at runtime so we can apply the method to any field we need to vaildate
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    console.log("handle submit");
    e.preventDefault();
    //uses validate function to check for errors
    const errors = this.validate();
    //logs any errors to the state
    this.setState({ errors: errors || {} });
    console.log(errors)
    //if there are any errors cancle submit
    if (errors) return;
    //if not than handle the submit
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  //Used to render buttons in forms
  renderButton(label) {
    return (
      <button  className="btn btn-primary">
        {label}
      </button>
    );
  }

  //Used to render select drop down boxes in forms (specifically regions)
  renderSelect(name, label, options) {
    const { data, errors } = this.state;
    return (
      <Select
      name={name}
      value={data[name]}
      label={label}
      options={options}
      onChange={this.handleChange}
      error={errors[name]}
      />
      );
  }
  
  //used for rendering form fields that are required / need to be validated
  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  //not having an error field makes renderConditionalInput used for non required form fields
  renderConditionalInput(name, label, type = "text") {
    const { data } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
      />
    );
  }

  //this is the renderRadio for radiobuttons used for conditional rendering
  renderRadio(name, label, updateRender, firstButton, secondButton) {
    const { data, errors } = this.state;

    return (
      <RadioButton
        label={label}
        value={data[name]}
        name={name}
        updateRender={updateRender}
        firstButton={firstButton}
        secondButton={secondButton}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  //this in the renderRegularRadio for radiobuttons NOT used for conditional rendering
  renderRegularRadio(name, label, updateRadioCheck) {
    const { data, errors } = this.state;

    return (
      <RegularRadio
        value={data[name]}
        name={name}
        label={label}
        updateRadioCheck={updateRadioCheck}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  //renders check boxes in the forms
  renderCheckBox(name, label, availability, updateChecked, region) {
    const { data, errors, isChecked } = this.state;
    return (
      <CheckBox
        name={name}
        value={data[name]}
        region={region}
        label={label}
        updateChecked={updateChecked}
        availability={availability}
        onChange={this.handleChange}
      />
    );
  }
}

export default Form;