import React, { Component } from 'react';
import involvedRA from "../resources/images/involvedRA.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faShieldHeart, faBookOpenReader } from '@fortawesome/free-solid-svg-icons';
import results3 from '../resources/images/results3.jpg';
import results4 from '../resources/images/results4.jpg';
import results5 from '../resources/images/results5.jpg';
import Row from 'react-bootstrap/Row';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
  } from "recharts";

  const ORF = [
    {
      name: "Student 1",
      Fall: 14,
      Winter: 27,
      Spring: 47
    },
    {
      name: "Student 2",
      Fall: 32,
      Winter: 51,
      Spring: 86
    },
    {
      name: "Student 3",
      Fall: 9,
      Winter: 38,
      Spring: 98
    },
    {
      name: "Student 4",
      Fall: 24,
      Winter: 60,
      Spring: 113
    },
    {
      name: "Student 5",
      Fall: 9,
      Winter: 45,
      Spring: 74
    }
  ];

  const bookLevel = [
    {
      name: "Student 1",
      Fall: 1,
      Winter: 9,
      Spring: 15
    },
    {
      name: "Student 2",
      Fall: 4,
      Winter: 16,
      Spring: 21
    },
    {
      name: "Student 3",
      Fall: 1,
      Winter: 15,
      Spring: 19
    },
    {
      name: "Student 4",
      Fall: 3,
      Winter: 17,
      Spring: 20
    },
    {
      name: "Student 5",
      Fall: 4,
      Winter: 16,
      Spring: 20
    }
  ];


class Stories extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                <div className="shadow results" ><h1 className="Head title">Results</h1></div>

                <div className='pad-bottom'>
                    <div className="row w3-padding">
                        <div className="col w3-padding-16" id="reqBack">
                            <div className="">
                                <div className="" >
                                    <a className="big-quote-text">“Because of high quality instruction in school and participation in Reading Allies, 
                                    our students are moving from struggling readers to proficient readers at high rates. Reading Allies is a big win for all.” </a>
                                    <a className='big-quote-cred'> <br></br> - Dr. Mike Daria, Tuscaloosa City <br></br>Schools Superintendent </a>
                                </div>
                            </div>  
                        </div>
                        <div className="col w3-padding-16">
                            <img className="sizeIMG shadow" alt="volunteer teaching letters" id="" src={results3}/>
                        </div>
                    </div>
                </div>


                <div className='pad-bottom extra-pad'>
                    <blockquote className='pad-bottom'>
                        <br></br>
                        <a className='big-quote-text align-left'>Volunteering with Reading Allies allowed me to see the actual impact I could have <br></br>on a student. 
                            Knowing that this program has helped put him on track for <br></br>future success filled me with so much joy.</a>
                        <cite><span class="cite-last-name">Reading Allies Volunteer</span></cite>
                    </blockquote>
                </div>

                <div className='pad-bottom'>
                    <div className="row w3-padding">
                        <div className="col w3-padding-16" id="reqBack">
                            <div className="">
                                <div className="" >
                                <div className=''>
                                    <h1 className='centerToGraphBL'>Book Level</h1>
                                        <BarChart
                                            width={700}
                                            height={500}
                                            data={bookLevel}
                                            margin={{
                                                top: 5,
                                                right: 20,
                                                left: 20,
                                                bottom: 5
                                                        }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip wrapperStyle={{ width: 130, backgroundColor: 'white' }}/>
                                            <Legend layout='vertical' />
                                            <Bar dataKey="Fall" fill="#9e1b32" />
                                            <Bar dataKey="Winter" fill="#1E6B52" />
                                            <Bar dataKey="Spring" fill="#0077C8" />
                                        </BarChart>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div className="col w3-padding-16">
                            <p className='descrStyleBL'>On average, Reading Allies students show a year’s worth of growth in their book level over 
                            the course of a single semester. Students are assessed on their book level at the beginning of the first semester 
                            (Fall), at the beginning of second semester (Winter), and at the end of the second semester before summer break 
                            (Spring). Utilizing Rigby PM Readers, students work within a book level range of 1-24 with a Level 1 corresponding to 
                            a kindergarten level and Level 24 corresponding to a third-grade level. The data listed in the graph are a sample of 
                            first, second, and third grade Reading Allies students.  </p>
                        </div>
                    </div>
                </div>

                <div className="row w3-padding pad-bottom">
                    <div className="col imgCol">
                        <img className="sizeIMG shadow" alt="volunteer teaching letters" id="" src={results4}/> 
                    </div>
                    <div className="col imgCol">
                        <img className="sizeIMG shadow" alt="volunteer teaching letters" id="" src={results5}/>
                    </div>
                </div>

                <div className="row w3-padding">
                    <div className="col pad-bottom">
                            <div className="quote2" >
                                <a className="quote-text">“My student went from only knowing the alphabet to reading full sentences by herself.” </a>
                                <a className='quote-cred'> <br></br> - Reading Allies Volunteer </a>
                            </div> 
                    </div>
                    <div className="col">
                             <div className="quote3" >
                                <a className="quote-text">“Reading Allies filled the missing link in our workforce development initiatives.” </a>
                                <a className='quote-cred'> <br></br> -Chamber of Commerce Representative  </a>
                            </div> 
                    </div>
                </div>

                <div className='pad-bottom extra-pad'>
                    <blockquote className='pad-bottom'>
                        <br></br>
                        <a className='big-quote-text align-left'>I felt like I was actually doing something meaningful for the community and for our students.</a>
                        <cite><span class="cite-last-name">Reading Allies Volunteer</span></cite>
                    </blockquote>
                </div>
                <div className='pad-bottom'>
                    <div className="row w3-padding">
                        <div className="col w3-padding-16" id="reqBack">
                            <div className="">
                                <div className="" >
                                <div className=''>
                                    <h1 className='centerToGraph'>Oral Reading Fluency</h1>
                                    <BarChart
                                        width={700}
                                        height={500}
                                        data={ORF}
                                        margin={{
                                            top: 5,
                                            right: 20,
                                            left: 20,
                                            bottom: 5
                                                    }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip wrapperStyle={{ width: 130, backgroundColor: 'white' }} />
                                        <Legend layout='vertical' />
                                        <Bar dataKey="Fall" fill="#9e1b32" />
                                        <Bar dataKey="Winter" fill="#1E6B52" />
                                        <Bar dataKey="Spring" fill="#0077C8" />
                                    </BarChart>
                                </div>
                                </div>
                            </div>  
                        </div>
                        <div className="col w3-padding-16">
                            <p className='descrStyle'>Oral Reading Fluency is a standardized data point used to calculate how many words per minute a student can read. 
                                On average, Reading Allies students doubled their words per minute read on state standardized tests. In addition,
                                 most students’ ORF accuracy percentages grew to 95% accuracy and above, considered the mastery level by school 
                                 districts. Students are assessed on their ORF at the beginning of the first semester (Fall), at the beginning of
                                second semester (Winter), and at the end of the second semester before summer break (Spring). </p>
                        </div>
                    </div>
                </div>
                

                {/* <div className="row noBtm">
                    <div className="col centerText spaceTop">
                        <button className='results-btn'><a className='buttonTxt-white' href='/register'>Become a Reading Ally</a></button>
                    </div>
                </div>
                     */}
            </React.Fragment>
        );
    }
}
 
export default Stories;