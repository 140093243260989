import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import axios from "axios";

import uuid from 'react-native-uuid';
import fName from "../config.json";
import lName from "../config.json";
import region from "../config.json";
import phoneNumber from "../config.json";
import email from "../config.json";
import dayTimeSelect from "../config.json";
import address1 from "../config.json";
import address2 from "../config.json";
import city from "../config.json";
import state from "../config.json";
import zip from "../config.json";
import willingTo from "../config.json";
import understand from "../config.json";
import comments from "../config.json";
import pastVolunteer from "../config.json";
import schoolName from "../config.json";
import studentName from "../config.json";
import howDidHear from "../config.json";
import facilitator from "../config.json";
import apiUrl from "../config.json";


//importing text fields for each form input header from the config.json
// import {
//   fName,
//   lName,
//   eMail,
//   phoneNumber,
//   region,
//   address1,
//   address2,
//   city,
//   state,
//   zip,
//   dayTimeSelect,
//   willingTo,
//   understand,
//   comments,
//   pastVolunteer,
//   schoolName,
//   studentName,
//   howDidHear,
//   facilitator,
//   apiUrl,
// } from "../config.json";

class RegisterForm extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      region: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      dayTimeSelect: [],
      willingTo,
      understand,
      comments: "",
      pastVolunteer,
      schoolName: "",
      studentName: "",
      howDidHear: "",
      facilitator,
    },
    errors: {},
    //fill in regions list to be replaced once we link to the database
    regionList: [
      
    ],
    checkbox: false,
    checkboxCount: 0,
    availability: [],
    semester: "Spring2022"
  };
  //notice that .required() is only included for required fields in the schema
  //Joi is used as our validation tool for the form
  schema = {
    //Validation for all of the data inputs 
    //Joi.string() or boolean tells validation the input type, allow('') allows it to be blank, required() says it has to be inputted to be able to submit
    //Label fields come from the config.json file
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().required().label("Last Name"),
    email: Joi.string().required().email().label("Email"),
    phoneNumber: Joi.string().required().label("Cell Phone Number"),
    region: Joi.string().required().label("Region"),
    address1: Joi.string().allow('').label("Address Line 1"),
    address2: Joi.string().allow('').label("Address Line 2"),
    city: Joi.string().allow('').label("City"),
    state: Joi.string().allow('').label("State"),
    zipCode: Joi.string().allow('').max(5).label("Zip Code"),
    dayTimeSelect: Joi.array().allow('').label("Please select all times and locations you are avaliable and willing to volunteer with Reading Allies this fall"),
    willingTo: Joi.boolean().label("Would you be willing to volunteer for more than one session per week?"),
    understand: Joi.boolean().label("I am able to participate for all 10 weeks of the program."),
    comments: Joi.string().allow('').label("If you still want to participate but cannot be there all 10 weeks, please leave a comment and we will work with you"),
    pastVolunteer: Joi.boolean().label("Have you ever volunteered in the past?"),
    schoolName: Joi.string().allow('').label("If so, please indicate what school you volunteered at"),
    studentName: Joi.string().allow('').label("What is the name of the student you worked with"),
    howDidHear: Joi.string().required().label("How did you hear about Reading Allies?"),
    facilitator: Joi.boolean().label("Are you willing to become a facilitator?")
  };

  //used to properly render and format all of the volunteer times in the checkboxes
  async componentWillMount() {
    //const separatedAvailabilities = getAvailabilities();
    let filtered = [];
    let checked = false;

    const {data: rawAvailabilities} = await axios.get("https://reading-allies-api.herokuapp.com/api/" + "availability");
    const separatedAvailabilities = rawAvailabilities.map(function(rawAvailabilitues){
      return {_id: rawAvailabilitues.availability_ID, weekDay: rawAvailabilitues.weekDay, time: rawAvailabilitues.time, schoolName: rawAvailabilitues.schoolName, region: rawAvailabilitues.region_ID.toString()};
    });
    
    //Formatting availabilities from a raw seperated form to Day + Time @ School Format
    for (let i = 0; i < separatedAvailabilities.length; i++) {
      const display =
        separatedAvailabilities[i].weekDay +
        " " +
        separatedAvailabilities[i].time +
        " @ " +
        separatedAvailabilities[i].schoolName;
      filtered[i] = { ...separatedAvailabilities[i], display, checked };
    }
    //console.log(filtered);
    
    this.setState({ availability: filtered });

    //Getting a raw region list from the API and mapping it into a usable string format for the selectInput field
    const {data: regionListRaw} = await axios.get("https://reading-allies-api.herokuapp.com/api/" + "region");
    const regionListSecondary = regionListRaw.map(function(regionListRaw){
        return {_id: regionListRaw.region_ID.toString(), name: regionListRaw.regionName};
    });

    const regionList = regionListSecondary.filter(r => r.name !== 'National');
    this.setState({regionList});
  }

  //handling the submit after handleSubmit() in form has done proper validation
  doSubmit = () => {
    //a window confirm box pops up in the window to confirm submission
    const confirmBox = window.confirm("Are you sure you want to register this Volunteer?")
    if (confirmBox === true) {
    // Call the server
    const boxCheckCheckBox = this.checkDatBox();
      //if availability checkbox is checked
    if(boxCheckCheckBox){
      //create volunteer
      this.createVolunteer();
      //reset form to blank for a new submission
      this.resetRender();
      this.props.history.push("/");
    }
    else{
      //else alert user that they need to check one of the boxes
      alert('At least one availability must be checked'); 
      this.props.history.push("/volunteer");
    }
    //pushes the page back to register volunteer if unsuccesful, push to home page if succesful
  }
};

resetRender(){
  //logic resides above in doSubmit()
  alert('We have received your sign up. Thank you! The Reading Allies team will be in contact with more information');
  //resets data to empty so that when the page is refreshed the form is yet again blank
    const reset ={
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      region: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      dayTimeSelect: [],
      willingTo,
      understand,
      comments: "",
      pastVolunteer,
      schoolName: "",
      studentName: "",
      howDidHear: "",
      facilitator
    }
    //setting the this.state.data fields
    this.setState({data: reset});
  }

  async createVolunteer(){
    //uuid.v4 generates a unique ID for the volunteer
    const id = uuid.v4();
    console.log(id);
    //spread operartor (...) pulls all of data from the state
    const data = {...this.state.data};

    //axios.post creates a new volunteer in the API based on what has been set in the state
    //information in the () is taking the new volunteer data ex. data.firstName and seeting it to the API variables to store it ex. firstName: data.firstName
    await axios.post("https://reading-allies-api.herokuapp.com/api/" + "volunteer", { volunteer_ID: id, firstName: data.firstName, lastName: data.lastName, email: data.email, phoneNumber: data.phoneNumber, multipleSessions: data.willingTo, comments: data.comments, heardFrom: data.howDidHear, mailingAddressLineOne: data.address1, mailingAddressLineTwo: data.address2, city: data.city, state: data.state, zipCode: data.zipCode, lastSchool: data.schoolName, semester: this.state.semester, lastStudent: data.studentName, region_ID: Number(data.region), facilitator: data.facilitator, timeStamp: "" });
    //
    this.createVolunteerAvailability(id);
  }

  //Creates the selected availability that the volunteer checked in the form and axios.post puts it in the API
  async createVolunteerAvailability(id){
    for(var i = 0; i < this.state.availability.length; i++){
      if(this.state.availability[i].checked === true){
        await axios.post("https://reading-allies-api.herokuapp.com/api/" + "volavail", {volunteer_ID: id, availability_ID: Number(this.state.availability[i]._id)});
      }
    }
  }

  //Please ignore naming convention - late work session + frustration = bad naming conventions
  checkDatBox(){
    //Goes through array of availabilities and uses if statement to check the state and see if the a checkBox has been checked
    for(var i = 0; i < this.state.availability.length; i++){
      if(this.state.availability[i].checked === true){
        return true;
      }
    }
    return false;
  }
  
  //used to pass the boolean logic of selected radio button to be used in radioButton and in the return statement for conditionally rendering the form
  updateRender = (willRender, name) => {

    var copy = {...this.state.data};
    if(name === 'understand'){
      copy.understand = !willRender;
    }
    else{
      copy.pastVolunteer = !willRender;
    }
    this.setState({ shouldRender: willRender });
    this.setState({data: copy});
  };

  //inverted of the above method for when the "Yes" button is what we want to activate the conditional rendering
  updateInverseRender = (willRender, name) => {
    var copy = {...this.state.data};
    if(name === 'understand'){
      copy.understand = willRender;
    }
    else{
      copy.pastVolunteer = willRender;
    }
    this.setState({ shouldInverseRender: willRender });
    this.setState({data: copy});
  };

  updateRadioCheck = (value, name) => {
    var copy = {...this.state.data};
    if(name === 'willingTo'){
      copy.willingTo = value;
    }
    if(name === 'facilitator'){
      copy.facilitator = value;
    }
    this.setState({data: copy});

  }

  updateCheckbox = (isChecked, id) => {
    var availabilityCopy = [...this.state.availability];
    for(var i = 0; i < availabilityCopy.length; i++){
      if(availabilityCopy[i]._id === id){
        availabilityCopy[i].checked = isChecked;
      }
    }
    this.setState({availability: availabilityCopy});
  }

  render() {
    return (
      <div>
        <h1 style = {{textAlign: "center", fontSize: "40px"}}>Volunteer Requirements</h1>
        <p>Reading Allies wouldn’t exist without dedicated community volunteers like you! To sign-up to volunteer, visit the form below and 
          select your region from the drop-down menu. Once you select your region, continue filling out the form to see all available dates 
          and times to volunteer.
          <br></br><br></br>
          Reading Allies sessions are about 30 minutes. Programming generally runs for 10 weeks per semester, with sessions occurring twice 
          per week. You can sign-up to volunteer for as many or as few time slots as you prefer, but please keep in mind you are signing up for 
          the duration of the semester.  
          <br></br><br></br>
          Volunteers must be at least 16 years old and adhere to the background check requirements of the local school district. Upon signing up 
          to volunteer, your area’s Volunteer Coordinator will be in contact with you with more information about training and placement. If you 
          have technical difficulties with the form, please contact the Reading Allies team at readingallies@gmail.com. 
          <br></br><br></br>
          Don’t see your area listed to volunteer? Reach out to our team to start building readers with Reading Allies! </p>
        <h1 style = {{textAlign: "center", fontSize: "40px"}}>Reading Allies Volunteer Sign Up</h1>
        <form onSubmit={this.handleSubmit}>
          {/*Use renderConditionalInput for optional fields , and renderInput for required fields*/}
          {this.renderInput("firstName", "First Name")}
          {this.renderInput("lastName", "Last Name")}
          {this.renderInput("email", "Email")}
          {this.renderInput("phoneNumber", "Cell Phone Number")}
          {/*Use renderSelect field for drop downs i.e regions*/}
          {this.renderSelect("region", "Region", this.state.regionList)}
          {this.renderConditionalInput("address1", "Address Line 1")}
          {this.renderConditionalInput("address2", "Address Line 2")}
          {this.renderConditionalInput("city", "City")}
          {this.renderConditionalInput("state", "State")}
          {this.renderConditionalInput("zipCode", "Zip Code")}
          {this.state.data.region && this.renderCheckBox("dayTimeSelect", "Please select all times and locations you are available and willing to volunteer with Reading Allies this fall", this.state.availability, this.updateCheckbox, this.state.data.region)}
          {/*renderRegularRadio renders a radio button that doesnt have any conditional inputs tied to it*/}
          {this.renderRegularRadio("willingTo", "Would you be willing to volunteer for more than one session per week?", this.updateRadioCheck)}
          {/*Yes and no are passed in as strings so we can determine which button option we want to be true or false in radioButton*/}
          {this.renderRadio("understand", "I am able to participate for all 10 weeks of the program.", this.updateRender, "Yes", "No")}
          {/*below logic is used to hide certain inputs until the proper button is selected to conditionally render that piece of the form*/}
          {this.state.shouldRender && this.renderConditionalInput("comments", "If you still want to participate but cannot be there all 10 weeks, please leave a comment and we will work with you")}
          {/*shouldInverseRender is used to flip which boolean we are looking for on the button click so we do not have to create a flipped radioButton component*/}
          {this.renderRadio("pastVolunteer", "Have you ever volunteered in the past?", this.updateInverseRender, "No", "Yes")}
          {this.state.shouldInverseRender && this.renderConditionalInput("schoolName", "If so, please indicate what school you volunteered at")}
          {this.state.shouldInverseRender && this.renderConditionalInput("studentName", "What is the name of the student you worked with")}
          {/* to-do */}
          {this.renderRegularRadio("facilitator", "Are you willing to become a facilitator?", this.updateRadioCheck)}
          {this.renderInput("howDidHear", "How did you hear about Reading Allies?")}
          {this.renderButton("Register to Volunteer")}
        </form>
      </div>
    );
  }
}

export default RegisterForm;
