import React, {Component} from 'react';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
//For API Requests
import axios from 'axios';
import { toast } from 'react-toastify';
import Link from 'react-router-dom/Link';
import apiUrl from '../config.json'

class CoordDataTable extends Component {
    state = { data:[],
        region:[] } 
        async componentDidMount(){
            await this.getAdmins();
            await this.getRegions();
            await this.getAdminRegion();
            $(document).ready(function () {
                setTimeout(function(){
                $('#example').DataTable();
                 } ,1000);
            });
        }
        componentWillUnmount(){
        }
        async getAdmins(){
            await axios.get("https://reading-allies-api.herokuapp.com/api/" + 'admin').then(res => 
            {
              //Storing users detail in state array object
              this.setState({data: res.data});      
            });
        }

        //
        async getRegion(result){
          var res;
          const regions = this.state.region;
          regions.map((region) => {
            if(region.region_ID === result.region_ID){
              res = region.regionName;
            }
          });
            var data = this.state.data;
            data.map((r) => {
                if (result.volunteer_ID === r.volunteer_ID)
                 result.regionName = res;
            });
             this.setState({data});
            
        }

        //
        async getAdminRegion(){
          var data = this.state.data;
          var res;
         this.state.data.map((result) => {
              res = this.getRegion(result);
          });
          (this.setState({data}));
        }

        //Gets regions from API
        async getRegions(){ 
            const regions = await axios.get("https://reading-allies-api.herokuapp.com/api/" + 'region');
            this.setState({region: regions.data});
        }  

        handleDelete = async admin => {
          //creates a new variable thats equal to the state data 
          const originalCoordinator = this.state.data;
          //creates a new availabilities variable and filters all availabilities into it except for the one passed in by the delete button
          const admins = originalCoordinator.filter(v => v.admin_ID !== admin.admin_ID);
          //Sets the new availabilities back to the state
          this.setState({ data: admins });

          //try catch block for deleting the selected availability from the API
          try {
            //delete caller
            await axios.delete("https://reading-allies-api.herokuapp.com/api/" + 'admin/' + admin.admin_ID + '?admin_ID=' + admin.admin_ID);
          } 
          //catch block for weird errors
          catch (ex) {
            if (ex.response && ex.response.status === 404)
              toast.error("This Coordinator has already been deleted.");
              //ensures were setting the data back to all of them
              this.setState({ data: originalCoordinator });
          }
        };

        


    render() { 
        return (
            <div className="MainDiv">
          
          {/* Jumbotron */}
      <div className="jumbotron text-center" style= {{backgroundColor: "#9e1b32"}}>
          <h3 style={{fontSize: "50px", color: "white"}}>Reading Allies Coordinators</h3>
      </div>

      {/* Create Coordinator button - Links it to a create coordinator form */}
      <Link to="/register-coordinator">
        <button className="btn btn-primary m-2">
          Create Coordinator
        </button>
      </Link>

      {/* Table logic */}
      <div className="container">
          <table id="example" className="table table-hover table-bordered">
          {/* Table Header */}
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Region</th>
              <th>Details</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
          {/* Table Body */}  
          {this.state.data.map((result) => {
            //Maps the data and dynamically creates a row for each coordinator
            return (
                 <tr key={result.admin_ID}>
                  <td>{result.firstName} {result.lastName}</td>
                  <td>{result.email}</td>
                  <td>{result.regionName}</td>
                  <td>
                   {/* Details button that is linked to an edit coordinator form */} 
                  <Link to={`/coordinators/${result.admin_ID}`}>
                    <button className="btn btn-primary">
                      Details</button></Link></td> 

                  {/* Delete button, Window confirm box asks user to confirm, upon confirmtion calls handle delete */}
                  <td><button className="btn btn-danger"
                  onClick={() => {
                    const confirmBox = window.confirm("Do you want to delete this info?")
                    if (confirmBox === true) {
                      this.handleDelete(result)
                    }
                  }}
                  >Delete</button></td>
                </tr>
            )
          })}
          </tbody>
        </table>
        </div>
      </div>
        );
    }
}
export default CoordDataTable;