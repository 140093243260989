import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import axios from "axios";
import fName from "../config.json";
import lName from "../config.json";
import region from "../config.json";
import phoneNumber from "../config.json";
import email from "../config.json";
import address1 from "../config.json";
import address2 from "../config.json";
import city from "../config.json";
import state from "../config.json";
import zip from "../config.json";
import apiUrl from "../config.json";
//import { Link } from "react-router-dom";

//names imported from config file to be used for rendering field names
// import {
//   fName,
//   lName,
//   region,
//   phoneNumber,
//   eMail,
//   address1,
//   address2,
//   city,
//   state,
//   zip,
//   apiUrl,
// } from "../config.json";

class NewCoordinatorForm extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      address1: "",
      address2: "", 
      city: "",
      state: "",
      zipCode: "",
      access: "",
    },
    errors: {},

    regionList: []
  };

  schema = {
    //schema requirements for validation
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().required().label("Last Name"),
    email: Joi.string().max(50).required().email().label("Email"),
    phoneNumber: Joi.number().required().label("Phone Number"),
    access: Joi.string().required().label("Region"),
    password: Joi.string().min(8).max(100).required().label("Password"), 
    address1: Joi.string().allow("").label("Address Line 1"),
    address2: Joi.string().allow("").label("Address Line 2"),
    city: Joi.string().allow("").label("City"),
    state: Joi.string().allow("").label("State"),
    zipCode: Joi.string().allow("").label("Zip Code")
  };

  async componentDidMount() {
    //pulling the regions from the API "axios.get" and storing them in a raw array list
    const {data: regionListRaw} = await axios.get("https://reading-allies-api.herokuapp.com/api/" + "region");
    //mapping the raw list into a new toString array with a region ID and region name
    const regionList = regionListRaw.map(function(regionListRaw){
      return {_id: regionListRaw.region_ID.toString(), name: regionListRaw.regionName};
    });
    //setting the new region list to the state for use
    this.setState({regionList});
  }

  doSubmit = async () => {
    //windows pop up box on submit to confirm the user wants to submit
    const confirmBox = window.confirm("Are you sure you want to register this coordinator?")
    if (confirmBox === true) {
      //once the user confirms we set the inputted data to the state
      const data = this.state.data;
      //console.log(data);
      //Password hashing for protection
      const passwordHash = require('bcryptjs');
      const salt = passwordHash.genSaltSync(10);
      //console.log(salt); 
      const hashed = passwordHash.hashSync(data.password, salt);
      //const isTrue = passwordHash.compareSync("Password", hashed);
      //console.log(isTrue);
      // passwordHash.genSalt(saltRounds, function(salt){
      //   console.log(salt);
      //   passwordHash.hash(data.password, salt, function(hash){
      //     console.log(hash);
      //   });
      // });
      //const hashed = passwordHash.generate(data.password);
      //const hashed = "placeholder";
      //console.log(hashed);
      //Posting the new coordinator to the API using the data from the state and the variables used within the backend
      await axios.post("https://reading-allies-api.herokuapp.com/api/" + "admin", {firstName: data.firstName, lastName: data.lastName, email: data.email, phoneNumber: data.phoneNumber, mailingAddressLineOne: data.address1, mailingAddressLineTwo: data.address2, city: data.city, state: data.state, zipCode: data.zipCode, password: hashed, region_ID: Number(data.access)});
      //pushing the page back to /coordinators
      this.props.history.push("/coordinators");
    }
  };

  render() {
    return (
      <div>
        {/*Create the header and styling */}
        <h1 style = {{textAlign: "center", fontSize: "40px"}}>New Coordinator Form</h1>
        {/*Create the form and the user input fields, functions are inhereted from the Form component*/}
        <form onSubmit={this.handleSubmit}>
          {/*Example: {this.inputType("generic name for data", what is rendered above)} */}
          {this.renderInput("firstName", "First Name")}
          {this.renderInput("lastName", "Last Name")} 
          {this.renderInput("email", "Email")}
          {this.renderInput("phoneNumber", "Phone Number")}
          {this.renderInput("address1", "Address Line 1")}
          {this.renderInput("address2", "Address Line 2")}
          {this.renderInput("city", "City")}
          {this.renderInput("state", "State")}
          {this.renderInput("zipCode", "Zip Code")}
          {this.renderInput("password", "Password")}
          {this.renderSelect("access", "Region", this.state.regionList)}
          {this.renderButton("Register")}
        </form>
      </div>
    );
  }
}

export default NewCoordinatorForm;
