import React from "react";

//radioButton component with conditional rendering possible due to the updateRender and passing first and second button logic
const RadioButton = ({
  name,
  label,
  updateRender,
  firstButton,
  secondButton,
  error
}) => {
  return (
    <div>
      <div className="radio-group">
        <label htmlFor={name}>{label}</label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name={name}
          id="flexRadioDefault1"
          onClick={() => updateRender(false, name)}
        />

        <label className="form-check-label" htmlFor="flexRadioDefault1">
          {/*firstButton passed in from renderRadio w/ boolean conditional rendering. This first button defaults to false,
           so the second button needs to be clicked to activate the condition*/}
          {firstButton}
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name={name}
          id="flexRadioDefault2"
          onClick={() => updateRender(true, name)}
        />

        <label className="form-check-label" htmlFor="flexRadioDefault2">
          {secondButton}
        </label>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default RadioButton;
