import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import axios from "axios";
import fName from "../config.json";
import lName from "../config.json";
import region from "../config.json";
import phoneNumber from "../config.json";
import email from "../config.json";
import address1 from "../config.json";
import address2 from "../config.json";
import city from "../config.json";
import state from "../config.json";
import zip from "../config.json";
import willingTo from "../config.json";
import understand from "../config.json";
import comments from "../config.json";
import pastVolunteer from "../config.json";
import schoolName from "../config.json";
import studentName from "../config.json";
import howDidHear from "../config.json";
import facilitator from "../config.json";
import apiUrl from "../config.json";

//importing text fields for each form input header from the config.json
// import {
//   fName,
//   lName,
//   eMail as email,
//   phoneNumber,
//   region,
//   address1,
//   address2,
//   city,
//   state,
//   zip,
//   willingTo,
//   understand,
//   comments,
//   pastVolunteer,
//   schoolName,
//   studentName,
//   howDidHear,
//   facilitator,
//   apiUrl,
// } from "../config.json";

//toast.configure()
class EditVolunteer extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      region: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      willingTo,
      understand,
      comments: "",
      pastVolunteer,
      schoolName: "",
      studentName: "",
      howDidHear: "",
      facilitator: false,
    },
    volAvail: [],
    availabilities: [],
    errors: {},
    regionName: "",
    //fill in regions list to be replaced once we link to the database
    regionList: [],
    availability: [],
    semester: "Spring2022",
    volId: "",
  };
  //notice that .required() is only included for required fields in the schema
  //Joi is used as our validation tool for the form
  schema = {
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().required().label("Last Name"),
    email: Joi.string().required().email().label("Email"),
    phoneNumber: Joi.number().required().label("Phone Number"),
    region: Joi.string().required().label("Region"),
    address1: Joi.string().allow("").label("Address Line 1"),
    address2: Joi.string().allow("").label("Address Line 2"),
    city: Joi.string().allow("").label("City"),
    state: Joi.string().allow("").label("State"),
    zipCode: Joi.string().allow("").max(5).label("Zip Code"),
    availabilityInfo: Joi.string().label("Availability"),
    //dayTimeSelect: Joi.array().allow('').label(dayTimeSelect),
    willingTo: Joi.boolean().label("Would you be willing to volunteer for more than one session per week?"),
    understand: Joi.boolean().label("I am able to participate for all 10 weeks of the program."),
    comments: Joi.string().allow("").label("If you still want to participate but cannot be there all 10 weeks, please leave a comment and we will work with you"),
    pastVolunteer: Joi.boolean().label("Have you ever volunteered in the past?"),
    schoolName: Joi.string().allow("").label("If so, please indicate what school you volunteered at"),
    studentName: Joi.string().allow("").label("What is the name of the student you worked with"),
    howDidHear: Joi.string().required().label("How did you hear about Reading Allies?"),
    facilitator: Joi.boolean().label("Are you willing to become a facilitator?"),
  };

  //used to properly render and format all of the volunteer times in the checkboxes
  async componentWillMount() {
    //splitting the windows path name to get the ID and setting it to the state
    var path = window.location.pathname;
    var split = path.split("/");
    this.setState({ volId: split[split.length - 1] });
    //Getting the region ID and the region name from the API then setting it to the state
    const { data: regionListRaw } = await axios.get("https://reading-allies-api.herokuapp.com/api/" + "region");
    const regionList = regionListRaw.map(function (regionListRaw) {
      return {
        _id: regionListRaw.region_ID.toString(),
        name: regionListRaw.regionName,
      };
    });
    this.setState({ regionList });
  }

  async componentDidMount() {
    //Calls the server to get the volunteer information
    const { data: volunteer } = await axios.get("https://reading-allies-api.herokuapp.com/api/" + "volunteer/" + this.state.volId + "?volunteer_ID=" + this.state.volId);
    //Calls the functions necessary to build the form upon page loading
    await this.getAvailabilities();
    this.populateForm(volunteer);
    this.getRegion();
  }

  async getRegion() {
    //Gets the stored region attached to the volunteer called
    var regionName = "";
    regionName = this.state.regionList.filter((r) => r._id == this.state.data.region);
    this.setState({ regionName: regionName[0].name });
  }

  async getAvailabilities() {
    //Storing users detail in state array object
    await axios.get("https://reading-allies-api.herokuapp.com/api/" + "volavail/").then((res) => {this.setState({ volAvail: res.data });});

    //Storing user availability detail in state array object
    await axios.get("https://reading-allies-api.herokuapp.com/api/" + "availability/").then((res) => {this.setState({ availabilities: res.data });});

    var volunteer = this.state.data;
    var check1 = "";
    var check = "";
    var checkValue = "";

    //get all instances from volAvail where the volunteer is present/has availability recorded
    check1 = this.state.volAvail.filter(
      (v) => v.volunteer_ID === this.state.volId
    );
    //take each instance to find its individual school, time and day
    check1.map((c) => {
      if (c === null) return;
      //filter based on the individual availability's ID from the volavail record
      check = this.state.availabilities.filter(
        (a) => a.availability_ID === c.availability_ID
      );
      if (check === null) return;
      //Append it into a useable format
      var line =
        check[0].schoolName +
        " " +
        check[0].time +
        " " +
        check[0].weekDay +
        ", ";
      checkValue += line;
      volunteer.availabilityInfo = checkValue;
    });
    //set it to the state
    this.setState({ data: volunteer });
  }

  async populateForm(volunteer) {
    //function to call mapToView and catch any errors while populating the form data
    try {
      this.mapToView(volunteer);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) console.log("ERRROR");
    }
  }

  mapToView = (volunteer) => {
    //Setting details in the state data to data pulled from the selected volunteer in the API
    const data = this.state.data;
    (this.state.volId = volunteer.volunteer_ID),
      (data.firstName = volunteer.firstName),
      (data.lastName = volunteer.lastName),
      (data.email = volunteer.email),
      (data.phoneNumber = volunteer.phoneNumber),
      (data.region = volunteer.region),
      (data.address1 = volunteer.mailingAddressLineOne),
      (data.address2 = volunteer.mailingAddressLineOne),
      (data.city = volunteer.city),
      (data.state = volunteer.state),
      (data.zipCode = volunteer.zipCode),
      // HERE*********************
      (data.willingTo = volunteer.multipleSessions),
      (data.understand = volunteer.understand),
      (data.comments = volunteer.comments),
      (data.pastVolunteer = volunteer.pastVolunteer),
      (data.schoolName = volunteer.lastSchool),
      (data.studentName = volunteer.lastStudent),
      (data.howDidHear = volunteer.heardFrom),
      (data.facilitator = volunteer.facilitator),
      (data.region = volunteer.region_ID.toString()),
      this.setState({ data });
  };

  doSubmit = () => {
    //Windows pop up box asking the user to confirm or deny changes
    const confirmBox = window.confirm("Do you want to edit this info?");
    if (confirmBox === true) {
      // Create volunteer call 
      this.createVolunteer();
      alert("Information recieved. Thanks!");
      //push back to the data table
      this.props.history.push("/volunteers");
    }
  };

  async createVolunteer() {
    const data = { ...this.state.data };

    //Takes the new data from the state and calls the API to overwrite what exists there
    await axios.put(
      "https://reading-allies-api.herokuapp.com/api/" + "volunteer/" + this.state.volId,
      {
        volunteer_ID: this.state.volId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        multipleSessions: data.willingTo,
        comments: data.comments,
        heardFrom: data.howDidHear,
        mailingAddressLineOne: data.address1,
        mailingAddressLineTwo: data.address2,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
        lastSchool: data.schoolName,
        semester: this.state.semester,
        lastStudent: data.studentName,
        region_ID: Number(data.region),
        facilitator: data.facilitator,
      }
    );
  }

  render() {
    return (
      <div>
        <h1 style = {{textAlign: "center", fontSize: "40px"}}>Edit Volunteer Form</h1>
        <form onSubmit={this.handleSubmit}>
          {/*Use renderConditionalInput for optional fields, and renderInput for required fields*/}
          {this.renderInput("firstName", "First Name")}
          {this.renderInput("lastName", "Last Name")}
          {this.renderInput("email", "Email")}
          {this.renderInput("phoneNumber", "Phone Number")}
          {this.renderConditionalInput("address1", "Address Line 1")}
          {this.renderConditionalInput("address2", "Address Line 2")}
          {this.renderConditionalInput("city", "City")}
          {this.renderConditionalInput("state", "State")}
          {this.renderConditionalInput("zipCode", "Zip Code")}
          <p>Region: {this.state.regionName}</p>
          <p>Volunteer Avaliability: {this.state.data.availabilityInfo}</p>
          {/* Ternary operators: The condition being checked ? Expression to execute if truthy : Expression to execute if falsey */}
          <p>Would you be willing to volunteer for more than one session per week?</p>
          <p>{this.state.data.willingTo ? "Yes" : "No"}</p>
          <p>Able to participate for all 10 weeks?</p>
          <p>{this.state.data.understand ? "No" : "Yes"}</p>
          <p>Comments</p>
          <p>
            {this.state.data.comments === ""
              ? "Volunteer didnt leave a comment"
              : this.state.data.comments}
          </p>
          <p>Ever volunteered in the past?</p>
          <p>
            {this.state.data.pastVolunteer
              ? "No"
              : "Volunteered at " +
                this.state.data.schoolName +
                " with student " +
                this.state.data.studentName}
          </p>
          {/* to-do */}
          <p>Are you willing to become a facilitator?</p>
          <p>{this.state.data.facilitator ? "Yes" : "No"}</p>
          <p>How did you hear about Reading Allies?</p>
          <p>{this.state.data.howDidHear}</p>
          {this.renderButton("Save Edits")}
        </form>
      </div>
    );
  }
}

export default EditVolunteer;