import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import axios from "axios";
import apiUrl from '../config.json'

class ChangePassword extends Form {

    state = {
        data: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          },
          errors: {},
    };

    schema = {
        oldPassword: Joi.string().min(8).max(100).required().label("Password"),
        newPassword: Joi.string().min(8).max(100).required().label("Password"),
        confirmPassword: Joi.string().min(8).max(100).required().label("Password"),
      };

    async componentWillMount(){
        const currentAdminId = sessionStorage.getItem('admin').toString();
        const {data: currentAdmin} = await axios.get("https://reading-allies-api.herokuapp.com/api/" + "admin/" + currentAdminId + "?admin_ID=" + currentAdminId);
        this.setState({admin : currentAdmin});
    }

    doSubmit = () => {
        //console.log(this.state.admin);
        //console.log(this.state.data.oldPassword);
        const passwordHash = require('bcryptjs');
        if(passwordHash.compareSync(this.state.data.oldPassword, this.state.admin.password)){
            if(this.state.data.newPassword === this.state.data.confirmPassword && this.state.data.newPassword !== this.state.data.oldPassword){
                const salt = passwordHash.genSaltSync(10);
                const changedPassword = passwordHash.hashSync(this.state.data.newPassword, salt);
                this.submitChange(changedPassword);
            }
            else{
                alert('"New Password" and "Confirm Password" must match'); 
                const resetData = {
                    oldPassword: this.state.data.oldPassword,
                    newPassword: "",
                    confirmPassword: "",
                };
                this.setState({data : resetData});
            }
        }
        else{
            alert('"Current Password" must be equal to your existing password');
            const resetData = {
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
            };
            this.setState({data : resetData});
        }
      };

      async submitChange(hashed){
        const admin = this.state.admin;
        const confirmBox = window.confirm("Are you sure you want to change your password?")
        if (confirmBox === true) {
            try{
                await axios.put("https://reading-allies-api.herokuapp.com/api/" + "admin/" + admin.admin_ID , {admin_ID: admin.admin_ID, firstName: admin.firstName, lastName: admin.lastName, email: admin.email, phoneNumber: admin.phoneNumber, mailingAddressLineOne: admin.mailingAddressLineOne, mailingAddressLineTwo: admin.mailingAddressLineTwo, city: admin.city, state: admin.state, zipCode: admin.zipCode, password: hashed, region_ID: admin.region_ID});
                this.props.history.push("/coordinators");
            }
            catch(error){
                console.log('I failed and you suck');
            }
        }
      }

    render() {
        return (
          <div>
            <h1>Change Password</h1>
            <form onSubmit={this.handleSubmit}>
                {this.renderInput("oldPassword", "Current Password", "password")}
                {this.renderInput("newPassword", "New Password", "password")}
                {this.renderInput("confirmPassword", "Confirm Password", "password")}
                {this.renderButton("Submit")}
            </form>
          </div>
        );
      }
}

export default ChangePassword;