import React, { Component } from 'react';
import CoordDataTable from './coordDataTable';
import VolunteerDataTable from './volunteerDataTable';

class AdminHomepage extends Component {
    state = {};

    render(){
        return(
            <React.Fragment>
            <h1>Homepage works!</h1>
            <CoordDataTable></CoordDataTable>
            <VolunteerDataTable></VolunteerDataTable>
            </React.Fragment>
        )
    }
}

export default AdminHomepage;
