import React from 'react';

//radioButton component without conditional rendering capabilitiies 
const RegularRadio = ({name, label, updateRadioCheck, error}) => {
    return (  
        <React.Fragment>
          <div className="radio-group">
            <label htmlFor={name}>{label}</label>
          </div>
    
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id="name1"
              onClick={() => updateRadioCheck(true, name)}
            />
    
            <label className="form-check-label" htmlFor="name1">
            Yes
            </label>
          </div>
    
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id="name2"
              onClick={() => updateRadioCheck(false, name)}
            />
    
            <label className="form-check-label" htmlFor="name2">
              No
            </label>
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          </React.Fragment>
      ); 
}
 
export default RegularRadio;