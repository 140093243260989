import React, { Component } from 'react';
import styles from '../resources/css/proj.module.css';
import ariLogo from "../resources/images/partnerlogos/ARI-Logo.png";
import earlyChildhood from "../resources/images/partnerlogos/BamaDepEarlyChildhoodEducation.png";
import bryant from "../resources/images/partnerlogos/Bryant_Alternate_Gray.png";
import chamber from "../resources/images/partnerlogos/Chamber-logo.png";
import tefLogo from "../resources/images/partnerlogos/TEFlogo.png";
import rotary from "../resources/images/partnerlogos/RotaryTuscaloosa.png";
import involvedRA from "../resources/images/involvedRA.jpg";


class Involved extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment><body className={styles.backOFF} style={{ width: "100%" }}>



                <div style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: 50,
                    fontFamily: "helvetica",
                    height: 120,
                    marginTop: 20,
                    marginBottom: 20,
                    marginRight: 40,
                    marginLeft: 40
                }} className="shadow" id="invHead"><h1 className="head1 title">Become a Reading Ally</h1></div>

                <div className="descr spaceBottom">
                    <a className="shrinkText">Reading Allies, a University of Alabama System initiative, is an innovative literacy framework that helps first, second,
                        and third graders reading below grade-level rise to grade-level standards through tailored, in-depth tutoring. With 52% of
                        Alabama’s third graders not meeting grade-level reading standards, Reading Allies is using proven strategies to invest in our students,
                        schools and state.</a>
                </div>

                <div className="row w3-padding">
                    <div className="col w3-padding-16" id="boxblack">
                        <div className="sub">Join Our Partners</div>
                        <div className="partners spaceTop">
                            <div className="content1">
                                <img src={ariLogo} alt="Cinque Terre" width="200" height="auto" />
                            </div>

                            <div className="content2">
                                <img src={earlyChildhood} alt="Forest" width="100" height="auto" />
                            </div>

                            <div className="content3">
                                <img src={bryant} alt="Northern Lights" width="150" height="auto" />
                            </div>

                            <div className="content4">
                                <img src={chamber} alt="Mountains" width="200" height="auto" />
                            </div>

                            {/* <!-- <div className="content5">
      <img src="./resources/images/partnerlogos/UAS-Vertical-No-Tag-Color.png" alt="Northern Lights" width="220" height="auto"/>
  </div> --> */}

                            <div className="content6">
                                <img src={tefLogo} alt="Mountains" width="150" height="auto" />
                            </div>

                            <div className="content7">
                                <img src={rotary} alt="Northern Lights" width="200" height="auto" />
                            </div>

                            {/* <!-- <div className="content8">
      <img src="./resources/images/book.jpg" alt="Mountains" width="100" height="100">
    </div> --> */}
                        </div>

                    </div>
                    <div className="col w3-padding-16" id="boxblack">
                        <div className="sub centerText">Volunteer as an Individual</div>
                        <div className="descrBox spaceBottom">
                            <a className="shrinkText spaceTop sideSpace">Reading Allies, a University of Alabama System initiative, is an innovative literacy framework that helps first, second,
                                and third graders reading below grade-level rise to grade-level standards through tailored, in-depth tutoring. With 52% of
                                Alabama’s third graders not meeting grade-level reading standards, Reading Allies is using proven strategies to invest in our students,
                                schools and state.</a>
                        </div>
                    </div>
                </div>

                <div className="row w3-padding pad">
                    <div className="col w3-padding-16 shadow textBack" id="reqBack">
                        <div className="sub centerText spaceTop">Requirements for Volunteering</div>
                        <div className="spaceBottom">
                            <a className="shrinkText spaceTop" id="textWhite">Reading Allies, a University of Alabama System initiative, is an innovative literacy framework that helps first, second,
                                and third graders reading below grade-level rise to grade-level standards through tailored, in-depth tutoring. With 52% of
                                Alabama’s third graders not meeting grade-level reading standards, Reading Allies is using proven strategies to invest in our students,
                                schools and state.</a>
                        </div>
                    </div>
                    <div className="col w3-padding-16">
                        <img className="sizeIMG shadow" alt="volunteer teaching letters" id="" src={involvedRA} />
                    </div>
                </div>
                <div className="row noBtm marg">
                    <div className="col centerText spaceTop">
                        <img className="smallBtn" alt="volunteer teaching letters" id="" src={involvedRA} />
                    </div>
                    <div className="col centerText spaceTop">
                        <img className="smallBtn" alt="volunteer teaching letters" id="" src={involvedRA} />
                    </div>
                    <div className="col centerText spaceTop">
                        <img className="smallBtn" alt="volunteer teaching letters" id="" src={involvedRA} />
                    </div>
                </div>
                <div className="containter spaceTop" id="" style={{textAlign: 'center'}}><h1 className="head1 title"><b style={{color: "#1e6b52"}}>G</b><b style={{color: "#0077c8"}}>i</b><b style={{color: "#9e1b32"}}>v</b><b style={{color: "#ffcd10"}}>e</b> <b style={{color: "#000000"}}>to the Cause</b></h1></div>
                <div className="centerText "><h1 className="shadow padSide" id="abt" style={{fontFamily: "helvetica", fontSize: 50, color: "white"}}>CLICK HERE TO GIVE!</h1></div>
                <div className="centerText spaceTop"><h1 style={{fontFamily: "helvetica"}}>What can your donation do?</h1></div><div className="descr">
                    <a className="shrinkText">Reading Allies, a University of Alabama System initiative, is an innovative literacy framework that helps first, second,
                        and third graders reading below grade-level rise to grade-level standards through tailored, in-depth tutoring. With 52% of
                        Alabama’s third graders not meeting grade-level reading standards, Reading Allies is using proven strategies to invest in our students,
                        schools and state.Reading Allies, a University of Alabama System initiative, is an innovative literacy framework that helps first, second,
                        and third graders reading below grade-level rise to grade-level standards through tailored, in-depth tutoring. With 52% of
                        Alabama’s third graders not meeting grade-level reading standards, Reading Allies is using proven strategies to invest in our students,
                        schools and state.</a>

    </div> 



                </body></React.Fragment>
        );
    }
}
 
export default Involved;