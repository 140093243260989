import React, { Component } from 'react';
import axios from 'axios';
import Link from 'react-router-dom/Link';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import { toast } from 'react-toastify';
//import { bool } from 'prop-types';
import apiUrl from '../config.json'



class RegionDataTable extends Component {
    state = { 
        region: []
     } 

     async componentDidMount(){
        await this.getRegions();
        $(document).ready(function () {
            setTimeout(function(){
            $('#example').DataTable();
             } ,1000);
        });
    }

      //gets the regions from the API and stores them in the states
    async getRegions(){ 
        const regions = await axios.get("https://reading-allies-api.herokuapp.com/api/" + 'region');
        this.setState({region: regions.data});
    }

    handleDelete = async admin => {
        //Stores all of the regions from the state into the a new list that is filtered to delete the selected region
        const originalRegions = this.state.region;
        const newRegions = originalRegions.filter(v => v.region_ID !== admin.region_ID);
        this.setState({ region: newRegions });
        
        //try to delete the selected region from the API
        try {
          await axios.delete("https://reading-allies-api.herokuapp.com/api/" + 'region/' + admin.region_ID + '?region_ID=' + admin.region_ID);
        } 
        //catch to check for errors
        catch (ex) {
          if (ex.response && ex.response.status === 404)
            toast.error("This region has already been deleted.");
            //if an error is caught the regions are restored to the original state
            this.setState({ region: originalRegions });
        }
      };

    render( ) { 
      var notStatewide = true;
        return (
            <React.Fragment>
              {/*Jumbotrpn creation and styling */}
            <div className="MainDiv">
            <div className="jumbotron text-center" style= {{backgroundColor: "#9e1b32"}}>
          <h3 style={{fontSize: "50px", color: "white"}}>Reading Allies Regions</h3>
      </div>
      {/* Button to create region that is linked to the form */}
      <Link to="/register-region">
        <button className="btn btn-primary m-2">
          Create Region
        </button>
      </Link>
      {/* Table Logic */}
      <div className="container">
          <table id="example" className="table table-hover table-bordered">
          <thead>
            <tr>
              <th>Region Name</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {/* Checking the region ID of the user to see if they are a statewide admin or not*/}
          {this.state.region.map((result) => {
            {if (result.region_ID === 574) {
              notStatewide = false}
            else{
              notStatewide = true
            }}
            return (
                 <tr key={result.region_ID}>
                  <td>{result.regionName}</td>

                  {notStatewide ? <td><button className="btn btn-danger"
                  onClick={() => {
                    const confirmBox = window.confirm("Do you want to delete this info?")
                    if (confirmBox === true) {
                      this.handleDelete(result)
                    }
                  }}
                  >Delete</button></td> : <td></td>}
                </tr>
            )
          })}
          </tbody>
        </table>
        </div>
      </div>
      </React.Fragment>
        )
    }
}
 
export default RegionDataTable;