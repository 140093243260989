import React, { Component } from 'react';
import axios from 'axios';
import Link from 'react-router-dom/Link';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import { toast } from 'react-toastify';
import apiUrl from '../config.json'

class AvailabilityDataTable extends Component {
    state = { 
        region: [],
        data: []
    }

    async componentDidMount() {
        await this.getRegions();
        await this.getAvailability();

            
        $(document).ready(function () {
                setTimeout(function(){
                $('#example').DataTable();
            } ,1000);
            });
    }

    async getRegions(){ 
      //imports regions from API and sets them to the state
        await axios.get("https://reading-allies-api.herokuapp.com/api/" + 'region/').then(res => 
            {
              //Storing users detail in state array object
              this.setState({region: res.data});      
            });
      
    }

    async getAvailability(){
      //imports availabilities and sets them to the state
        var data = await axios.get("https://reading-allies-api.herokuapp.com/api/" + 'availability/');
        var a = data.data;
        a.map(result => {
                       var name = this.state.region.filter(r => r.region_ID === result.region_ID);
                      result.name = name[0].regionName;
                   } 
                   )
        this.setState({data: a})
    }

    handleDelete = async admin => {
        //creates a new variable thats equal to the state data 
        const originalAvail = this.state.data;
        //creates a new availabilities variable and filters all availabilities into it except for the one passed in by the delete button
        const availabilities = originalAvail.filter(v => v.availability_ID !== admin.availability_ID);
        //Sets the new availabilities back to the state
        this.setState({ data: availabilities });
        
        //try catch block for deleting the selected availability from the API
        try {
          //delete caller
          await axios.delete("https://reading-allies-api.herokuapp.com/api/" + 'availability/' + admin.availability_ID + '?availability_ID=' + admin.availability_ID);
        }
        //catch block for weird errors
        catch (ex) {
          if (ex.response && ex.response.status === 404)
            toast.error("This availability has already been deleted.");
            //ensures were setting the data back to all of them
            this.setState({ data: originalAvail });
        }
      };

    render() { 
        return (
            <React.Fragment>
            <div className="MainDiv">

              {/* Jumbotron */}
            <div className="jumbotron text-center" style= {{backgroundColor: "#9e1b32"}}>
          <h3 style={{fontSize: "50px", color: "white"}}>Reading Allies Availabilties</h3>
      </div>

        {/* Create Availability button - Links it to a create availability form */}
      <Link to="/new-availability">
        <button className="btn btn-primary m-2">
          Create Time Slot
        </button>
      </Link>
      
      {/* Table logic */}
      <div className="container">
          <table id="example" className="table table-hover table-bordered">
            {/* Table Header */}
          <thead>
            <tr>
              <th>Region</th>
              <th>School</th>
              <th>Week Day</th>
              <th>Time</th>
              <th>Delete</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
          {this.state.data.map((result) => {
            //Maps the data and dynamically creates a row for each availability 
            return (
                 <tr key={result.availability_ID}>
                 
                  <td>{result.name}</td>
                  <td>{result.schoolName}</td>
                  <td>{result.weekDay}</td>
                  <td>{result.time}</td>
                {/* Delete button, Window confirm box asks user to confirm, upon confirmtion calls handle delete */}
                  <td><button className="btn btn-danger"
                  onClick={() => {
                    const confirmBox = window.confirm("Do you want to delete this info?")
                    if (confirmBox === true) {
                      this.handleDelete(result)
                    }
                  }}
                  >Delete</button></td>
                </tr>
            )
          })}
          </tbody>
        </table>
        </div>
      </div>
      </React.Fragment>  
        );
    }
}
 
export default AvailabilityDataTable;