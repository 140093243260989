import React, { Component } from 'react';
import { RedirectProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

class Logout extends Component {
    state = {  } 

    //closes out the user session
    componentDidMount(){
        sessionStorage.setItem('active', false);
        sessionStorage.setItem('admin', '');
        sessionStorage.setItem('region', '');
    }

    //redirects to the login screen
    render() { 
        return (
          <Redirect
              to={{
                pathname: "/login",
              }}
          />
        );
    }
}
 
export default Logout;