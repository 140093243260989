import React from "react";

//used to dynamically render checkboxes
const CheckBox = ({ name, label, availability, updateChecked, region }) => {
  return (
    <React.Fragment key={name}>
      <label htmlFor={name}>{label}</label>
      {availability.map((option) =>(
        (option.region === region) && <div className={name}>
          <input type="checkbox" key={option._id} value={option._id} onClick={() => updateChecked(!option.checked, option._id)} />
          {option.display}
        </div>
      ))}
    </React.Fragment>
  );
};

export default CheckBox;

