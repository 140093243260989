import { Dropdown } from "bootstrap"; //never delete this line!!!
import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "./readingallieslogo.gif";


const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light container-fluid">
      {/* Reading Allies logo */}
      {/* <a className="navbar-brand" href="https://readingallies.herokuapp.com">
        <img src={logo} style={{height: 100, marginTop: -15}}/>
      </a> */}
      <a className="navbar-brand" href="/">
        <img src={logo} style={{height: 100, marginTop: -15}}/>
      </a>
      {/*  */}
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      {/* Unprotected links */}
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
        {<NavLink className="nav-item nav-link" to="/about">
            About
          </NavLink>}
          <NavLink className="nav-item nav-link" to="/results">
            Results
          </NavLink>
          <NavLink className="nav-item nav-link" to="/volunteer">
            Volunteer
          </NavLink>
          {<NavLink className="nav-item nav-link" to="/team">
            Contact
          </NavLink>
           /*
          <NavLink className="nav-item nav-link" to="/involved">
            Get Involved
          </NavLink>
          <NavLink className="nav-item nav-link" to="/results">
            Results
          </NavLink> */}
          {/* Protected links */}
          {(!sessionStorage.getItem('active') || sessionStorage.getItem('active') === 'false') && <NavLink className="nav-item nav-link" to="/login">
            Login
          </NavLink>}
          {/*sessionStorage.getItem('region') === '574' && */}
          {(sessionStorage.getItem('region') === '574') && <NavLink className="nav-item nav-link" to="/register-coordinator">
            Create Coordinator
          </NavLink>}
          {(sessionStorage.getItem('active') === 'true') && <NavLink className="nav-item nav-link" to="/volunteers">
            Volunteers
          </NavLink>}
          {(sessionStorage.getItem('region') === '574') && <NavLink className="nav-item nav-link" to="/coordinators">
            Coordinators
          </NavLink>}
          {(sessionStorage.getItem('region') === '574') && <NavLink className="nav-item nav-link" to="/regions">
            Regions
          </NavLink>}
          {(sessionStorage.getItem('region') === '574') && <NavLink className="nav-item nav-link" to="/availabilities">
            Availabilities
          </NavLink>}
          {/* Dropdown for the indexs === disabled*/}
          {sessionStorage.getItem('region') === '-1' &&
          <li className="nav-index dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{marginTop: -15, marginBottom: -15, marginLeft: -15}}>
            Data Indexes
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <a className="dropdown-item" href="/volunteers">Volunteer Index</a>
            <a className="dropdown-item" href="/coordinators">Coordinator Index</a>
            <a className="dropdown-item" href="/regions">Region Index</a>
            <a className="dropdown-item" href="/availabilities">Availability Index</a>
          </div>
        </li>}
          {sessionStorage.getItem('active') === 'true' && <NavLink className="nav-item nav-link" to="/change-password">
            Change Password
          </NavLink>}
          {sessionStorage.getItem('active') === 'true' && <NavLink className="nav-item nav-link" to="/logout">
            Logout
          </NavLink>}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
